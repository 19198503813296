<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12">
      <div>
        <div class="card-body">
          <div class="row" style="background-color: #f3fdff">
            <div class="col-3 container">
              <img
                src="resources/images/medisync-big.svg"
                alt="Medisync"
                height="50px"
              />
            </div>
            <div class="col-6 header-text container">Notifikasi</div>
            <div class="col-3 container">
              {{ name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 px-4">
      <div class="card p-3" style="border-radius: 8px">
        <h6 class="m-3">Notifikasi</h6>
        <a-tabs
          default-active-key="1"
          @click.native.stop
          v-if="
            role === 'fileReviewer' || role === 'dokter' || role === 'koder'
          "
        >
          <a-tab-pane key="1" tab="Semua">
            <a-menu
              style="
                border-right: none !important;
                overflow-y: auto !important;
                max-height: 600px;
              "
              v-if="notifications.length"
            >
              <a-menu-item
                v-for="(notif, idx) in notifications"
                :key="idx"
                @click.native.stop
                @click="handleDirect(notif)"
                style="
                  white-space: wrap;
                  height: auto !important;
                  padding-bottom: 10px;
                  margin: 0 !important;
                  background-color: #f9fdff;
                "
                :style="{
                  backgroundColor: notif.isRead ? '' : '#f9fdff',
                  borderBottom: '1px solid #e5e5e5 !important',
                }"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <p class="m-0" style="color: #428a94; font-size: 14px">
                    {{ notif.title }}
                  </p>
                  <p class="m-0" style="color: #667085; font-size: 14px">
                    {{ notif.relativeTime }}
                  </p>
                </div>
                <p class="m-0" style="line-height: normal">
                  {{ notif.description }}
                </p>
              </a-menu-item>
            </a-menu>
            <div
              class="d-flex flex-column align-items-center justify-content-center py-2"
              v-else
            >
              <img
                src="resources/images/empty-notif.png"
                alt="avatar"
                width="100"
                height="100"
              />
              <p class="mt-2">Belum ada notifikasi</p>
            </div>
            <a-pagination
              class="pagination mt-3"
              :current="currentPage"
              :total="filteredNotifications.length"
              :page-size="pageSize"
              @change="handlePageChange"
              v-if="notifications.length"
            />
          </a-tab-pane>
          <a-tab-pane key="2" tab="Pemeriksaan Baru">
            <a-menu
              style="border-right: none !important"
              v-if="otherNotifications.length"
            >
              <a-menu-item
                v-for="notif in otherNotifications"
                :key="notif.id"
                @click.native.stop
                style="
                  white-space: wrap;
                  border-bottom: 1px solid #e5e5e5 !important;
                  height: auto !important;
                  padding-bottom: 10px;

                  margin: 0 !important;
                "
                :style="{ backgroundColor: notif.isRead ? '' : '#f9fdff' }"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <p class="m-0" style="color: #428a94; font-size: 14px">
                    {{ notif.title }}
                  </p>
                  <p class="m-0" style="color: #667085; font-size: 14px">
                    {{ notif.relativeTime }}
                  </p>
                </div>
                <p class="m-0" style="line-height: normal">
                  {{ notif.description }}
                </p>
              </a-menu-item>
            </a-menu>
            <div
              class="d-flex flex-column align-items-center justify-content-center py-2"
              v-else
            >
              <img
                src="resources/images/empty-notif.png"
                alt="avatar"
                width="100"
                height="100"
              />
              <p class="mt-2">Belum ada notifikasi</p>
            </div>
            <a-pagination
              class="pagination mt-3"
              :current="currentPage"
              :total="filteredNotifications.length"
              :page-size="pageSize"
              @change="handlePageChange"
              v-if="otherNotifications.length"
            />
          </a-tab-pane>
          <a-tab-pane key="3" tab="Balasan Klarifikasi">
            <a-menu
              style="border-right: none !important"
              v-if="chatNotifications.length"
            >
              <a-menu-item
                v-for="notif in chatNotifications"
                :key="notif.id"
                @click.native.stop
                style="
                  white-space: wrap;
                  border-bottom: 1px solid #e5e5e5 !important;
                  height: auto !important;
                  padding-bottom: 10px;

                  margin: 0 !important;
                "
                :style="{ backgroundColor: notif.isRead ? '' : '#f9fdff' }"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <p class="m-0" style="color: #428a94; font-size: 14px">
                    {{ notif.title }}
                  </p>
                  <p class="m-0" style="color: #667085; font-size: 14px">
                    {{ notif.relativeTime }}
                  </p>
                </div>
                <p class="m-0" style="line-height: normal">
                  {{ notif.description }}
                </p>
              </a-menu-item>
            </a-menu>
            <div
              class="d-flex flex-column align-items-center justify-content-center py-2"
              v-else
            >
              <img
                src="resources/images/empty-notif.png"
                alt="avatar"
                width="100"
                height="100"
              />
              <p class="mt-2">Belum ada notifikasi</p>
            </div>
            <a-pagination
              class="pagination mt-3"
              :current="currentPage"
              :total="filteredNotifications.length"
              :page-size="pageSize"
              @change="handlePageChange"
              v-if="chatNotifications.length"
            />
          </a-tab-pane>
        </a-tabs>
        <div v-else>
          <a-menu
            style="
              border-right: none !important;
              overflow-y: auto !important;
              max-height: 600px;
            "
            v-if="notifications.length"
          >
            <a-menu-item
              v-for="(notif, idx) in notifications"
              :key="idx"
              @click.native.stop
              @click="handleDirect(notif)"
              style="
                white-space: wrap;
                height: auto !important;
                padding-bottom: 10px;
                margin: 0 !important;
                background-color: #f9fdff;
              "
              :style="{
                backgroundColor: Number(notif.isRead) ? '' : '#f9fdff',
                borderBottom: '1px solid #e5e5e5 !important',
              }"
            >
              <div class="d-flex justify-content-between align-items-center">
                <p class="m-0" style="color: #428a94; font-size: 14px">
                  {{ notif.title }}
                </p>
                <p class="m-0" style="color: #667085; font-size: 14px">
                  {{ notif.relativeTime }}
                </p>
              </div>
              <p class="m-0" style="line-height: normal">
                {{ notif.description }}
              </p>
            </a-menu-item>
            <a-pagination
              class="pagination mt-3"
              :current="currentPage"
              :total="filteredNotifications.length"
              :page-size="pageSize"
              @change="handlePageChange"
            />
          </a-menu>
          <div
            class="d-flex flex-column align-items-center justify-content-center py-2"
            v-else
          >
            <img
              src="resources/images/empty-notif.png"
              alt="avatar"
              width="100"
              height="100"
            />
            <p class="mt-2">Belum ada notifikasi</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAllNotification,
  readNotification,
} from "@/services/axios/api/notifications"

export default {
  data() {
    return {
      name: "",
      notifications: [],
      otherNotifications: [],
      chatNotifications: [],
      currentPage: 1,
      pageSize: 10, // Batasan per halaman
      activeTab: "all", // Untuk menentukan tab aktif
      role: this.$store.state.user.role,
    }
  },
  created() {
    this.getName()
    this.getNotif()
  },
  computed: {
    // Filter berdasarkan tab yang aktif
    filteredNotifications() {
      if (this.activeTab === "all") {
        return this.notifications
      } else {
        return this.notifications.filter(
          (notif) => notif.category === this.activeTab,
        )
      }
    },
    // Hitung notifikasi berdasarkan halaman aktif
    paginatedNotifications() {
      const start = (this.currentPage - 1) * this.pageSize
      return this.filteredNotifications.slice(start, start + this.pageSize)
    },
  },
  methods: {
    handleToNotification() {
      this.$router.push("/notification")
    },
    async getNotif() {
      const { data } = await getAllNotification()
      this.notifications = data.data.map((e) => ({
        ...e,
        relativeTime: this.getRelativeTime(e.createdAt),
      }))
      this.chatNotifications = this.notifications.filter(
        (e) => e.isClarificationReply,
      )
      this.otherNotifications = this.notifications.filter(
        (e) => !e.isClarificationReply,
      )
    },
    async handleDirect(notif) {
      if (!notif.isRead) {
        await readNotification(notif.id)
      }
      this.getNotif()

      const currentPath = this.$route.path
      const targetRoute = this.$router.resolve(notif.directLink).route
      const fullUrl = `${window.location.origin}${
        this.$router.resolve(notif.directLink).href
      }`

      if (currentPath === targetRoute.path) {
        window.location.href = fullUrl
      } else {
        this.$router.push(notif.directLink).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            console.error("❌ Error navigasi:", err)
          }
        })
      }
    },
    handlePageChange(page) {
      this.currentPage = page
    },
    onTabChange() {
      this.currentPage = 1 // Reset pagination ketika ganti tab
    },
    getName() {
      this.name = localStorage.getItem("name")
    },
    getRelativeTime(dateString) {
      const now = new Date()
      const date = new Date(dateString)

      const diffInMilliseconds = now - date
      const diffInMinutes = Math.floor(diffInMilliseconds / 60000)

      if (diffInMinutes < 1) return "baru saja"
      if (diffInMinutes < 60) return `${diffInMinutes} menit lalu`
      if (now.getDate() === date.getDate()) {
        return date.toLocaleTimeString("id-ID", {
          hour: "2-digit",
          minute: "2-digit",
        })
      }
      return date.toLocaleDateString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;
}

.pagination {
  text-align: center;
  margin-top: 15px;
}
</style>
